export abstract class BaseAudioVisualizer {
  protected canvas: HTMLCanvasElement;
  protected audioContext: AudioContext;
  protected analyser: AnalyserNode;
  protected source: MediaElementAudioSourceNode | MediaStreamAudioSourceNode | null = null;
  private animationFrameId: number | null = null;
  protected enabled = false;
  protected noiseThreshold: number;
  protected amplificationFactor: number;

  constructor(elementId: string, audioSource: HTMLAudioElement | HTMLVideoElement | MediaStream, noiseThreshold = 5, amplificationFactor = 2) {
    const canvas = document.getElementById(elementId) as HTMLCanvasElement;
    if (!canvas) throw new Error(`Element with ID "${elementId}" not found`);
    this.canvas = canvas;
    this.setupCanvasResolution();
    // window.addEventListener('resize', this.setupCanvasResolution); // Add resize listener

    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 1024;
    this.noiseThreshold = noiseThreshold;
    this.amplificationFactor = amplificationFactor;

    if (audioSource instanceof HTMLAudioElement || audioSource instanceof HTMLVideoElement) {
      this.source = this.audioContext.createMediaElementSource(audioSource);
    } else if (audioSource instanceof MediaStream) {
      this.source = this.audioContext.createMediaStreamSource(audioSource);
    }

    if (this.source) {
      this.source.connect(this.analyser);
      this.analyser.connect(this.audioContext.destination);
    }

    this.drawVisualization();
  }

  private setupCanvasResolution = () => {
    const dpr = window.devicePixelRatio || 1;
    this.canvas.width = window.innerWidth * dpr;
    this.canvas.height = window.innerHeight * dpr;
    // this.canvas.width = window.innerWidth * dpr;
    // this.canvas.height = window.innerHeight * dpr;
    const canvasCtx = this.canvas.getContext("2d");
    if (canvasCtx) {
      canvasCtx.scale(dpr, dpr);
    }
  };

  protected abstract drawVisualization(): void;

  public enable() {
    if (!this.enabled) {
      this.enabled = true;
      this.audioContext.resume().then(() => this.animate());
    }
  }

  public disable() {
    if (this.enabled) {
      this.enabled = false;
      if (this.animationFrameId) cancelAnimationFrame(this.animationFrameId);
    }
  }

  public toggle() {
    this.enabled ? this.disable() : this.enable();
  }

  private animate = () => {
    if (this.enabled) {
      this.drawVisualization();
      this.animationFrameId = requestAnimationFrame(this.animate);
    }
  };
}
