import { useEffect, useRef, useState } from "react";
import { TransmutationVisualizer } from "./visualizations/TransmutationVisualizer";

export const App = () => {
  // const video = useRef<HTMLVideoElement | null>(null);
  const audio = useRef<HTMLAudioElement | null>(null);
  const visual = useRef<TransmutationVisualizer | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!visual.current && audio.current) {
      audio.current.volume = 1;
      visual.current = new TransmutationVisualizer("visualization", audio.current, 0, 50);
    }
  }, []);

  const toggle = () => {
    if (audio.current && !isPlaying) {
      audio.current.play();
      visual.current?.enable();
      setIsPlaying(true);
    } else {
      audio.current?.pause();
      visual.current?.disable();
      setIsPlaying(false);
    }
  }

  return (
    <>
      <canvas id="visualization" style={styles.visualization as React.CSSProperties} />
      <div style={styles.content as React.CSSProperties}>
        <audio
          controls={false}
          ref={audio}
          muted={false}
          crossOrigin="anonymous"
          onLoadedMetadata={(event) => {
            setDuration((event.target as HTMLAudioElement).duration);
          }}
        >
          <source src="/sample.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <button style={styles.button} onClick={() => toggle()}>{isPlaying ? 'pause' : 'play' }</button>
      </div>
    </>
  );
};

const styles = {
  // container: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   height: '100vh', // Full viewport height
  //   width: '100vw',  // Full viewport width
  //   backgroundColor: '#000',
  //   zIndex: 0,
  // },
  // video: {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   width: '100%',
  //   height: '100%',
  //   objectFit: 'cover', // fills the container while maintaining aspect ratio
  //   transform: 'translate(-50%, -50%)', // centers the video
  //   zIndex: 1,
  // },
  // videoTest: {
  //   width: '400px',
  //   height: '400px',
  //   zIndex: 1,
  // },
  visualization: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 2,
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height
    width: '100vw',  // Full viewport width
    zIndex: 99,
  },
  button: {
    fontSize: '1.5rem',
    padding: '1rem',
    backgroundColor: '#000',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    zIndex: 100,
    borderRadius: '100%',
    width: '100px',
    height: '100px',
    // display: 'none',
  }
};