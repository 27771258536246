import { BaseAudioVisualizer } from './BaseAudioVisualizer';

export class TransmutationVisualizer extends BaseAudioVisualizer {
    private rotationAngle = 0; // Initial rotation angle
    private rotationSpeed = 0.01; // Base rotation speed

    protected drawVisualization(): void {
        const canvasCtx = this.canvas.getContext("2d");
        if (!canvasCtx) return;

        // Audio-Reactive Pulsing Ring
        const bufferLength = this.analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        this.analyser.getByteFrequencyData(dataArray);

        // Calculate the average frequency for the pulsing effect
        const avgFrequency = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;

        // Update rotation angle and direction based on audio amplitude
        const amplitudeThreshold = 1; // Threshold to change direction
        const amplitudeFactor = avgFrequency / 128; // Normalize around 1

        // Change direction if average frequency crosses the threshold
        if (avgFrequency > amplitudeThreshold) {
            this.rotationSpeed = -Math.abs(this.rotationSpeed);
        } else {
            this.rotationSpeed = Math.abs(this.rotationSpeed);
        }

        // Update the rotation angle based on the amplitude
        this.rotationAngle += this.rotationSpeed * amplitudeFactor;

        // Clear the canvas
        canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        // Set line thickness and color
        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = "#d6d6d6";
        canvasCtx.fillStyle = "#d6d6d6";

        // Canvas center
        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;

        // Draw the original static visualization
        const baseRadius = Math.min(this.canvas.width, this.canvas.height) / 8;
        const radii = [
            baseRadius * 2.5,
            baseRadius * 3,
            baseRadius * 3.4,
            baseRadius * 3.5
        ];

        // Draw concentric circles
        for (let i = 0; i < radii.length; i++) {
            canvasCtx.beginPath();
            canvasCtx.arc(centerX, centerY, radii[i], 0, Math.PI * 2);
            canvasCtx.stroke();
        }

        // Draw Hexagon within the third circle
        const hexRadius = radii[1];
        const hexPoints = 6;
        const rotationOffset = -Math.PI / 2; // + this.rotationAngle;
        const hexVertices = [] as { x: number; y: number }[];
        canvasCtx.beginPath();
        for (let i = 0; i < hexPoints; i++) {
            const angle = (i / hexPoints) * Math.PI * 2 + rotationOffset;
            const x = centerX + Math.cos(angle) * hexRadius;
            const y = centerY + Math.sin(angle) * hexRadius;
            hexVertices.push({ x, y });
            if (i === 0) {
                canvasCtx.moveTo(x, y);
            } else {
                canvasCtx.lineTo(x, y);
            }
        }
        canvasCtx.closePath();
        canvasCtx.stroke();

        // Draw Triangle within the third circle
        const triangleRadius = hexRadius;
        const trianglePoints = 3;
        const triangleVertices = [] as { x: number; y: number }[];
        canvasCtx.beginPath();
        for (let i = 0; i < trianglePoints; i++) {
            const angle = (i / trianglePoints) * Math.PI * 2 + Math.PI / 6 + Math.PI; // + this.rotationAngle; // Rotated for alignment
            const x = centerX + Math.cos(angle) * triangleRadius;
            const y = centerY + Math.sin(angle) * triangleRadius;
            triangleVertices.push({ x, y });
            if (i === 0) {
                canvasCtx.moveTo(x, y);
            } else {
                canvasCtx.lineTo(x, y);
            }
        }
        canvasCtx.closePath();
        canvasCtx.stroke();

        // Draw lines connecting specific hexagon vertices
        const lineConnections = [
            { start: 0, end: 3 }, // 1st vertex to 4th vertex
            { start: 1, end: 4 }, // 2nd vertex to 5th vertex
            { start: 2, end: 5 }  // 3rd vertex to 6th vertex
        ];

        lineConnections.forEach(({ start, end }) => {
            const startPoint = hexVertices[start];
            const endPoint = hexVertices[end];
            canvasCtx.beginPath();
            canvasCtx.moveTo(startPoint.x, startPoint.y);
            canvasCtx.lineTo(endPoint.x, endPoint.y);
            canvasCtx.stroke();
        });

        // Draw small circles at intersections
        const smallCircleRadius = baseRadius / 3;
        for (let i = 0; i < trianglePoints; i++) {
            const start = triangleVertices[i];
            const end = triangleVertices[(i + 1) % trianglePoints];

            // Calculate the midpoint between the two vertices
            const midX = (start.x + end.x) / 2;
            const midY = (start.y + end.y) / 2;

            canvasCtx.beginPath();
            canvasCtx.arc(midX, midY, smallCircleRadius, 0, Math.PI * 2);
            canvasCtx.stroke();
        }

        // Draw the pulsing ring around the original visualization
        for (let i = 0; i < radii.length; i++) {
            const pulseRadius = radii[i] + avgFrequency / 6; // Adjust pulse radius
            canvasCtx.beginPath();
            canvasCtx.arc(centerX, centerY, pulseRadius, 0, Math.PI * 2);
            canvasCtx.strokeStyle = "#ffffff"; // Different color for the pulsing ring
            canvasCtx.lineWidth = 3; // Slightly thicker line for the pulsing ring
            canvasCtx.stroke();
        }
    }
}
